<template>
    <section>
        <div>
            <div class="mt-25 upload-file-in-MarketPlace">
                <el-radio-group v-model="creationType" size="mini">
                    <el-radio-button label="Upload Zip"></el-radio-button>
                    <el-radio-button label="Upload File"></el-radio-button>
                </el-radio-group>
            </div>
            <div v-if="creationType === 'Upload File'" v-loading="dialogLoading" class="upload-step">
                <el-upload class="upload-file-el-upload upload-file-in-MarketPlace mt-25 upload-demo" drag name="logo"
                    :on-change="uploadFile" action :show-file-list="false" :auto-upload="false"
                    accept=".pdf, .doc, .docx, .png, .jpg, .jpeg" multiple>
                    <div class="upload-file">
                        <div class="icon2 py-2">
                            <img src="@/assets/img/icons/Upload-file-3.svg" alt="Upload File" height="70" width="260"
                                class="upload-icon mt-15" />
                        </div>
                        <el-button class="mt-10" type="primary" plain>Select Files</el-button>
                        <p class="fs-9 mb-0 mt-1" style="color: #babdc2">OR</p>

                        <p class="text-dark fs-9 mb-0">Drag &amp; Drop files here</p>
                        <p class="mt-10 mb-20" style="color: grey">
                            Supported formats:
                            <span style="
                        background-color: #fff2f2;
                        color: red;
                        margin-left: 10px;
                        padding: 5px 10px;
                      ">PDF</span><span style="
                        background-color: #d8ebfd;
                        color: blue;
                        margin-left: 10px;
                        padding: 5px 10px;
                      ">DOC</span><span style="
                        background-color: #fff2e6;
                        color: #e6a23c;
                        margin-left: 10px;
                        padding: 5px 10px;
                      ">PNG</span><span style="
                        background-color: #fff2e6;
                        color: E6A23C;
                        margin-left: 10px;
                        padding: 5px 10px;
                      ">JPG</span>
                        </p>
                    </div>
                </el-upload>
                <el-row class="zip-file-list">
                    <draggable v-if="files && files.length" v-model="files">
                        <transition-group tag="div" name="flip-list" id="field-group" class="Files">
                            <div v-for="(file, index) in files" :key="JSON.stringify(file)" class="zip-file-item">
                                <a class="zip-file-name">
                                    <img :src="require('@/assets/img/icons/upload-active.svg')" alt="icon"
                                        class="FilesIcons" />
                                    <img v-if="
                                        file.raw.type == 'image/jpeg' ||
                                        file.raw.type == 'image/jpg'
                                    " :src="require('@/assets/img/icons/JPG.svg')" alt="icon" class="FilesIcons" />
                                    <img v-else-if="file.raw.type == 'image/png'"
                                        :src="require('@/assets/img/icons/PNG.svg')" alt="icon" class="FilesIcons" />

                                    <img v-else :src="require('@/assets/img/icons/pdf.svg')" alt="icon"
                                        class="FilesIcons" />
                                    {{ file.name }}
                                </a>
                                <div>
                                    <el-button type="text" icon="el-icon-bottom" @click="moveDown(index)"></el-button>
                                    <el-button type="text" icon="el-icon-top" @click="moveUp(index)"></el-button>
                                    <a @click="deleteItem(index)" class="ml-2">
                                        <i class="el-icon-circle-close"></i>
                                    </a>
                                </div>
                            </div>
                        </transition-group>
                    </draggable>
                </el-row>
            </div>
            <div v-else>
                <el-upload class="upload-file-el-upload upload-file-in-MarketPlace mt-25 upload-demo" drag name="logo"
                    :on-change="uploadZipFile" action :show-file-list="false" :auto-upload="false" accept=".zip">
                    <div class="upload-file">
                        <div class="upload-file">
                            <div class="icon2 py-2">
                                <img src="@/assets/img/icons/Upload-file-3.svg" alt="Upload Zip" height="70" width="260"
                                    class="upload-icon mt-15" />
                            </div>
                            <el-button class="mt-10" type="primary" plain>Upload Zip</el-button>
                            <p class="fs-9 mb-0 mt-1" style="color: #babdc2">OR</p>

                            <p class="text-dark fs-9 mb-0">
                                Drag &amp; Drop Zip file here
                            </p>
                            <p class="mt-10 mb-20" style="color: grey">
                                Supported formats in Zip :
                                <span style="
                          background-color: #fff2f2;
                          color: red;
                          margin-left: 10px;
                          padding: 5px 10px;
                        ">PDF</span><span style="
                          background-color: #d8ebfd;
                          color: blue;
                          margin-left: 10px;
                          padding: 5px 10px;
                        ">DOC</span><span style="
                          background-color: #fff2e6;
                          color: #e6a23c;
                          margin-left: 10px;
                          padding: 5px 10px;
                        ">PNG</span><span style="
                          background-color: #fff2e6;
                          color: E6A23C;
                          margin-left: 10px;
                          padding: 5px 10px;
                        ">JPG</span>
                            </p>
                        </div>
                    </div>
                </el-upload>

                <div class="zip-file-list">
                    <draggable v-if="zipFiles && zipFiles.length" v-model="zipFiles">
                        <transition-group tag="div" name="flip-list" id="field-group" class="Files">
                            <div class="zip-file-item" v-for="(zipFile, index) in zipFiles"
                                :key="JSON.stringify(zipFile)">
                                <a class="zip-file-name">
                                    <img :src="require('@/assets/img/icons/upload-active.svg')" alt="icon"
                                        class="icon-active" />
                                    <i class="el-icon-tickets"></i>
                                    {{ zipFile.name }}
                                </a>
                                <div class="action-buttons">
                                    <a @click="deleteZipFile(index)">
                                        <i class="el-icon-circle-close"></i>
                                    </a>
                                </div>
                            </div>
                        </transition-group>
                    </draggable>
                </div>
            </div>
            <div v-if="
                creationType === 'Upload File' || creationType === 'Upload Zip'
            " style="display: flex; flex-direction: column">
                <div class="Inputs-in-zip">
                    <span>Select Fields to Map</span>
                    <span>
                        <el-popover placement="top-start" trigger="hover"
                            content="All The fields will be selected automatically by default, but you can also choose specific fields if you prefer.">
                            <i class="el-icon-info" slot="reference"></i>
                        </el-popover>
                    </span>
                    <el-select v-model="selectedLabels" placeholder="Select a field" class="select-field mt-1" multiple
                        collapse-tags ref="labelsDropdown" style="width: 100%">
                        <el-option v-for="(Entityfield, index) in allCurrentEntityFields"
                            :key="Entityfield.template_key + index" :label="Entityfield.label"
                            :value="Entityfield.label"></el-option>
                    </el-select>
                </div>
                <div class="Inputs-in-zip">
                    <span>Store Your File In</span>
                    <el-select v-model="selectedFiles" placeholder="Select Upload" class="select-field mt-1"
                        ref="filesDropdown" multiple collapse-tags style="width: 100%">
                        <el-option v-for="(FileField, index) in allFileFields" :key="FileField.template_key + index"
                            :label="FileField.label" :value="FileField.template_key"></el-option>
                    </el-select>
                </div>

                <div class="Inputs-in-zip">
                    <span>Prompt (Optional)</span>
                    <el-input v-model="user_prompt" type="textarea" style="width: 100%" :autosize="{ minRows: 2 }"
                        placeholder="Enter Your Prompt"></el-input>
                </div>
            </div>
            

            <div v-if="LoadingDialogVisible" class="custom-popup-overlay">
                <div class="custom-popup-box">
                    <div class="text-center">
                        <img src="@/assets/img/Analyzing.gif" class="import-loading-gif"
                            style="height: 10vh; width: 10vh" />
                        <p>Please wait!</p>
                        <h1>Analyzing your zip file data</h1>
                    </div>
                </div>
            </div>
            <div v-if="ExtractDialogVisible" class="custom-popup-overlay">
                <div class="custom-popup-box">
                    <div class="text-center">
                        <img src="@/assets/img/Extracting.gif" class="import-loading-gif"
                            style="height: 10vh; width: 10vh" />
                        <p>Please wait!</p>
                        <h1>Extracting files and importing your data</h1>
                        <div v-if="zipFileUrl" class="zip-file-download">
                            <a :href="zipFileUrl" download="files.zip" class="btn btn-outline-success">
                                Your files Zip <i class="fa fa-download"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="ImportProcessInitiatedVisible" class="custom-popup-overlay">
                <div class="custom-popup-box">
                    <div class="text-center">
                        <img src="@/assets/img/Initiated-Icon.svg" class="import-loading-gif"
                            style="height: 10vh; width: 10vh" />
                        <h1>The import process has been initiated</h1>
                        <p>
                            We are currently in the process of importing your file data.
                            Sit back and relax for a while.
                            <!-- Once the import is complete, we will
                    notify you through <B>Email</B>. -->
                        </p>
                        <el-button type="primary" @click="gotoDashboard">Done</el-button>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer" v-if="creationType === 'Upload Zip'">
            <button class="btn btn btn-outline-danger btn-sm m-lr-1" type="button" @click="ImportZip = false">
                <i class="fa fa-close"></i> Cancel</button>&nbsp;&nbsp;

            <button class="btn btn-outline-success btn-sm m-lr-1" type="button" @click="AnalyzingData">
                <i class="fa fa-sign-out"></i> Next
            </button>
        </span>
        <span slot="footer" class="dialog-footer" v-else>
            <button class="btn btn btn-outline-danger btn-sm m-lr-1" type="button" @click="ImportZip = false">
                <i class="fa fa-close"></i> Cancel</button>&nbsp;&nbsp;

            <button class="btn btn-outline-success btn-sm m-lr-1" type="button" @click="ExportData">
                <i class="fa fa-sign-out"></i> Next
            </button>
        </span>
    </section>
</template>

<script>
import nodeAxios from "@/config/axios";
import JSZip from "jszip";
import { postAPICall } from "@/helpers/httpHelper";
import Swal from "sweetalert2";
export default {
    name: "GetDataFromAIThroughFiles",
    data() {
        return {
            dialogLoading: false,
            files: [],
            ImportZip: false,
            creationType: 'Upload File',
            file: '',
            fileData: '',
            fileDataArray: false,
            loading: false,
            zipFileUrl: null,
            zipFiles: [],
            selectedLabels: [],
            selectedFiles: [],
            user_prompt:'',
            LoadingDialogVisible:false,
            ExtractDialogVisible:false,
            ImportProcessInitiatedVisible:false,
        }
    },
    props: {
        allCurrentEntityFields: {
            type: Array,
        },
        allFileFields:{
            type: Array,
        }

    },
    methods: {
        moveDown(index) {
            if (index == this.files.length - 1) {
                return;
            }
            let sortedUsers = this.swapSingatureUsers(index, index + 1, this.files);
            this.files = [];
            this.files = sortedUsers;
        },
        moveUp(index) {
            if (index == 0) {
                return;
            }
            let sortedUsers = this.swapSingatureUsers(index, index - 1, this.files);
            this.files = [];
            this.files = sortedUsers;
        },
        swapSingatureUsers(fromIndex, toIndex, signaturedUsers) {
            let fromUser = signaturedUsers[fromIndex];
            let toUser = signaturedUsers[toIndex];
            signaturedUsers[fromIndex] = toUser;
            signaturedUsers[toIndex] = fromUser;
            return signaturedUsers;
        },
        async deleteItem(index) {
            this.files.splice(index, 1);
        },
        async processZipFromUrl(zipFileUrl) {
            try {
                const response = await fetch(zipFileUrl);
                const blob = await response.blob();
                const zipFile = new File([blob], "exported_files.zip", {
                    type: "application/zip",
                });
                this.zipFiles = [zipFile];
                await this.AnalyzingData();
            } catch (error) {
                this.$message.error("Failed to process zip file");
            }
        },
        async prepareZipFromFiles(files) {
            const zip = new JSZip();
            files.forEach((file) => {
                zip.file(file.name, file.raw);
            });
            try {
                const content = await zip.generateAsync({ type: "blob" });
                const zipFileUrl = URL.createObjectURL(content);
                const response = await fetch(zipFileUrl);
                const blob = await response.blob();
                const zipFile = new File([blob], "exported_files.zip", {
                    type: "application/zip",
                });
                return zipFile;
            } catch (error) {
                this.$message.error("Failed to convert files to zip");
            }
        },
        uploadFile(file) {
            if (
                file.raw.type === "application/pdf" ||
                file.raw.type === "application/msword" ||
                file.raw.type === "image/jpeg" ||
                file.raw.type === "image/png" ||
                file.raw.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ) {
                this.files.push(file);
            } else {
                this.$message.error("File format is not supported");
            }
        },
        async AnalyzingData() {
            this.closeFieldsDropdown();
            // this.uploadFilesInZip();
            if (this.zipFiles.length === 0) {
                this.$message.error("Please select a Zip file");
                return;
            }
            this.LoadingDialogVisible = true;
            try {
                let url = await this.getZipFileUrl(this.zipFiles[0]);
                if (!url) {
                    this.$message.error("Error Uploading File");
                    return;
                }
                let { groupedFields, groupedOutputTypes } =
                    this.groupFieldsByTemplate();
                let payload = {
                    url: url,
                    user_prompt: this.user_prompt,
                    fields: groupedFields,
                    fileFields: this.selectedFiles,
                    fileObj:
                        this.creationType == "Upload Zip"
                            ? this.zipFilesData
                            : this.getFieldsObjData(),
                    fieldOutputTypes: groupedOutputTypes,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    entity_id: this.entity_id,
                };
                const apiResponse = await postAPICall(
                    "POST",
                    "/process-zip-data",
                    payload
                );
                if (!apiResponse.success) {
                    this.$message.error("Error processing ZIP data. Please try again.");
                    return;
                }

                this.LoadingDialogVisible = false;
                this.ImportProcessInitiatedVisible = true;
            } catch (err) {
                if (err.response) {
                    console.error(
                        "Server responded with an error:",
                        err.response.status,
                        err.response.data
                    );
                    if (err.response.status === 404) {
                        this.$message.error(
                            "API endpoint not found. Please contact support."
                        );
                    } else if (err.response.status === 500) {
                        this.$message.error(
                            "Internal server error. Please try again later."
                        );
                    } else {
                        this.$message.warning(
                            "An issue occurred while processing ZIP files."
                        );
                    }
                } else if (err.request) {
                    console.error("No response received from the server:", err.request);
                    this.$message.error(
                        "No response from server. Please check your network connection."
                    );
                } else {
                    console.error("Error setting up the request:", err.message);
                    this.$message.warning("An unexpected error occurred.");
                }
            } finally {
                this.LoadingDialogVisible = false;
            }
        },
        getFieldsObjData() {
            let obj = {};
            this.files.map((e) => {
                obj[e.name] = { type: this.getMimeType(e.name), size: e.size };
            });
            return obj;
        },

        async uploadZipToCloud() {
            if (
                !this.bulkUploadDocsForm.hasSeparator &&
                !this.bulkUploadDocsForm.selectedField
            ) {
                return this.$message.warning("Please select a field");
            }
            let zipUrl;
            this.LoadingDialogVisible = true;

            if (this.creationType == "Upload Zip") {
                zipUrl = await this.getZipFileUrl(this.zipFiles[0]);
            } else {
                let zipFile = await this.prepareZipFromFiles(this.files);
                zipUrl = await this.getZipFileUrl(zipFile);
            }
            if (!zipUrl) {
                this.LoadingDialogVisible = false;
                return this.$notify.error({
                    title: "Error",
                    message: "Error Uploading File(s)",
                });
            }
            let payload = {
                url: zipUrl,
                fileObj:
                    this.creationType == "Upload Zip"
                        ? this.zipFilesData
                        : this.getFieldsObjData(),
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                entity_id: this.entity_id,
                form: this.bulkUploadDocsForm,
            };
            const apiResponse = await postAPICall(
                "POST",
                "/bulk-upload-documents",
                payload
            );
            if (!apiResponse.success) {
                this.LoadingDialogVisible = false;
                this.$message.error("Error processing ZIP data. Please try again.");
                return;
            } else {
                Swal.fire({
                    title: "Document Upload Process Started",
                    text: "Please check back in a few minutes.",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.LoadingDialogVisible = false;
                        this.bulkUploadDocsVisible = false;
                    }
                });
            }

            this.LoadingDialogVisible = false;
            this.bulkUploadDocsVisible = false;
        },
        async getZipFileUrl(zipFile) {
            var formData = new FormData();
            formData.append("files", zipFile.raw ? zipFile.raw : zipFile);
            formData.append("path", "temporary-files");
            formData.append("include_presigned_urls", true);
            let filesData = await nodeAxios.post(`/presignMultiUpload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            let url;
            if (Object.keys(filesData.data.urlsObject).length) {
                url =
                    filesData.data.urlsObject[Object.keys(filesData.data.urlsObject)[0]]
                        ?.downloadURL;
            }
            return url || "";
        },

        deleteZipFile(index) {
            this.zipFiles.splice(index, 1);
        },
        async uploadZipFile(zipFile) {
            if (this.zipFiles.length >= 1) {
                this.$message.warning("Only one Zip file can be uploaded at a time");
                return;
            }

            if (
                zipFile.raw.type === "application/zip" ||
                zipFile.raw.name.endsWith(".zip")
            ) {
                this.zipFiles.push(zipFile);
                const zip = new JSZip();
                await zip.loadAsync(zipFile.raw);
                const fileData = {};

                for (const [fileName, zipEntry] of Object.entries(zip.files)) {
                    if (!zipEntry.dir) {
                        const fileType = this.getMimeType(fileName);
                        const fileSize = zipEntry._data.uncompressedSize;
                        fileData[fileName] = { type: fileType, size: fileSize };
                    }
                }

                this.zipFilesData = fileData;
            } else {
                this.$message.error("Only ZIP files are supported");
            }
        },
        getMimeType(fileName) {
            const extension = fileName.split(".").pop().toLowerCase();
            const mimeTypes = {
                pdf: "application/pdf",
                doc: "application/msword",
                docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                jpg: "image/jpeg",
                jpeg: "image/jpeg",
                png: "image/png",
            };
            return mimeTypes[extension] || null;
        },
        async uploadFilesInZip() {
            const zipFile = this.zipFiles[0];
            const formData = new FormData();
            try {
                const zip = new JSZip();
                const zipContent = await zip.loadAsync(zipFile.raw || zipFile);
                for (const [fileName, zipEntry] of Object.entries(zipContent.files)) {
                    if (!zipEntry.dir) {
                        const fileData = await zipEntry.async("blob");
                        const file = new File([fileData], fileName);
                        formData.append("files", file);
                    }
                }
                formData.append("path", "template-data-documents");

                const filesData = await nodeAxios.post(
                    `/presignMultiUpload`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                return filesData;
            } catch (error) {
                this.$message.error("Failed to upload files in ZIP");
                console.error("Error uploading files:", error);
                return null;
            }
        },
        selectAllFields() {
            this.selectedLabels = this.allCurrentEntityFields.map(
                (field) => field.label
            );
        },
        selectFileFields() {
            this.selectedFiles = this.allFileFields.map(
                (field) => field.template_key
            );
        },
        groupFieldsByTemplate() {
            let groupedFields = {};
            let groupedOutputTypes = {};
            this.selectedFields = this.selectedLabels;
            let fields = this.selectedLabels?.length
                ? this.selectedLabels
                : Object.keys(this.fieldsLabelObject);
            let newArray = {};
            fields.forEach((label) => {
                const field = this.fieldsLabelObject[label];
                if (field) {
                    const templateName = field.template_name.includes("-")
                        ? field.template_name.split("-")[0]
                        : field.template_name;
                    if (!groupedFields[templateName]) {
                        groupedFields[templateName] = [];
                        groupedOutputTypes[templateName] = {};
                    }
                    if (field.isDataTableField) {
                        let key = `${field.template_id}#${field.data_table_key}`;
                        let dataTableField = this.currentEntityFieldsObject[key];
                        if (dataTableField?.label) {
                            let fieldLabelParts = field.label.split("-");
                            let actualFieldLabel = fieldLabelParts.pop().trim();
                            if (!newArray[dataTableField.label]) {
                                newArray[dataTableField.label] = [];
                            }
                            newArray[dataTableField.label].push(actualFieldLabel);
                        }
                    } else {
                        let FieldLabel = field.label.includes(" - ")
                            ? field.label.split(" - ").pop()
                            : field.label;
                        groupedFields[templateName].push(FieldLabel);
                        groupedOutputTypes[templateName][FieldLabel] = this.getOutputType(
                            field.inputType
                        );
                    }
                }
            });
            Object.keys(newArray).forEach((dataTableKey) => {
                let dataTableKeyParts = dataTableKey.split(" - ");
                let templateName = dataTableKeyParts[0];
                let tableName = dataTableKeyParts[1];
                if (groupedFields[templateName]) {
                    groupedFields[templateName].push({
                        [tableName]: newArray[dataTableKey],
                    });
                } else {
                    groupedFields[templateName] = [
                        {
                            [tableName]: newArray[dataTableKey],
                        },
                    ];
                }
            });
            return { groupedFields, groupedOutputTypes };
        },
        closeFieldsDropdown() {
      this.$refs.labelsDropdown?.blur();
      this.$refs.filesDropdown?.blur();
    },
    
    async ExportData() {
      this.closeFieldsDropdown();
      if (this.files.length === 0) {
        this.$message.error("Please select files");
        return;
      }
      const zip = new JSZip();
      this.files.forEach((file) => {
        zip.file(file.name, file.raw);
      });
      try {
        const content = await zip.generateAsync({ type: "blob" });
        const zipFileUrl = URL.createObjectURL(content);
        this.ExtractDialogVisible = true;
        await this.processZipFromUrl(zipFileUrl);
        this.ImportProcessInitiatedVisible = true;
      } catch (error) {
        this.$message.error("Failed to convert files to zip");
      }
    },
    gotoDashboard() {
      this.ImportProcessInitiatedVisible = false;
      this.ImportZip = false;
      if (this.isApplicationUserSide) {
        window.location.reload();
      } else {
        let UrlPathAdd = "";
        if (this.getIframeUrlInfo != null) {
          UrlPathAdd = `/if/${this.getIframeUrlInfo._id}/entity`;
        } else {
          UrlPathAdd = "/entity";
        }
        this.$router.push(UrlPathAdd);
      }
    },
    }
}

</script>